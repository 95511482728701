<template>
  <div class="page">
    <!--查询框-->
    <div class="search-box section page-header" style="margin-bottom: 1px;height: auto;">
      <el-form ref="searchForm" :inline="true" :model="query" size="medium">
        <el-form-item prop="parkingLotId">
          <SuggestParking
              @parkingName="onChangeParkingLot"
              v-model="query.parkingLotId"
              class="width260"
          />
        </el-form-item>
        <el-form-item prop="parkingLotGateId">
          <el-select
              v-model="query.parkingLotGateId"
              clearable
              placeholder="请选择出入口"
              class="width260"
          >
            <el-option
                v-for="item in parkingGateList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="operationType">
          <el-select
              v-model="query.operationType"
              clearable
              placeholder="请选择抬落杆类型"
              class="width260"
          >
            <el-option label="抬杆" value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="driveType">
          <!--            :default-time="['00:00:00', '23:59:59']"-->
          <el-date-picker
              v-model="query.timeValues"
              :picker-options="pickerOptions"
              align="right"
              end-placeholder="结束日期"
              range-separator="至"
              start-placeholder="开始日期"
              type="daterange"
              value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="doSearch">搜索</el-button>
          <el-button @click="resetQueryForm">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!--信息统计框-->
    <div class="search-box section page-header height50 padding20-32">
      <div class="content">
        <div class="content-item">
          <div class="title">信息条数：</div>
          <div class="text">{{ summaryData.total }}</div>
        </div>
      </div>
    </div>

    <div class="content-box section">
      <div>
        <!--        v-loading="isLoading"-->
        <el-table
            :data="dataList"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <!-- <el-table-column type="selection" width="55"> </el-table-column> -->
          <el-table-column
              align="center"
              label="抬杆编号"
              prop="code"
              width="240"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="车场名称"
              prop="parkingLotName"
              width="180"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="出/入口"
              prop="parkingLotGateName"
              width="120"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作用户"
              prop="userName"
              width="180"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="操作类型"
              prop="operationType"
              width="180"
          >
            <template slot-scope="scope">
              {{scope.row.operationType==0?'抬杆':'落杆'}}
            </template>
          </el-table-column>
          <el-table-column
              align="center"
              label="操作时间"
              prop="createDate"
              width="180"
          >
          </el-table-column>
          <el-table-column
              align="center"
              label="是否操作成功"
              prop="operateResult"
              width="180"
          >
            <template slot-scope="scope">
              {{scope.row.operateResult?'成功':'失败'}}
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="pagination-box">
        <el-pagination
            :current-page="pagination.pageNo"
            :page-size="pagination.pageSize"
            :total="pagination.totalSize"
            background
            layout="sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
    <!-- 完结弹出窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="completeDialogBeforeClosed"
        :visible.sync="completeDialogFormVisible"
        class="common-dialog"
        title="完结订单"
        width="550px"
    >
      <el-form
          ref="completeForm"
          :model="completeForm"
          :rules="rules"
          label-width="100px"
      >
        <el-form-item label="驶出时间" required>
          <el-form-item prop="endTime">
            <el-date-picker
                v-model="completeForm.endTime"
                placeholder="选择日期时间"
                type="datetime"
                value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">完结</el-button>
        <!-- <el-button @click="resetForm('completeForm')">重置</el-button> -->
      </div>
    </el-dialog>

    <!-- 修改车牌弹窗 -->
    <el-dialog
        :append-to-body="true"
        :before-close="modifyDialogBeforeClosed"
        :visible.sync="modifyDialogFormVisible"
        class="common-dialog"
        title="修改车牌"
        width="550px"
    >
      <el-form
          ref="modifyForm"
          :model="modifyForm"
          :rules="modifyRules"
          label-width="100px"
      >
        <el-form-item label="车牌颜色" required>
          <el-form-item prop="color">
            <el-select v-model="modifyForm.color" placeholder="请选择车牌颜色">
              <el-option label="黄牌" value="01"></el-option>
              <el-option label="蓝牌" value="02"></el-option>
              <el-option label="黑牌" value="06"></el-option>
              <el-option label="绿色" value="03"></el-option>
              <el-option label="黄绿" value="04"></el-option>
              <el-option label="白牌" value="23"></el-option>
              <el-option label="其他" value="99"></el-option>
            </el-select>
          </el-form-item>
        </el-form-item>
        <el-form-item label="车牌号码" prop="numPlate">
          <el-input v-model="modifyForm.numPlate"></el-input>
        </el-form-item>
        <el-form-item label="备注" prop="reason">
          <el-input
              v-model="modifyForm.reason"
              maxlength="100"
              show-word-limit
              placeholder="请输入备注"
              type="textarea"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeModify">取消</el-button>
        <el-button type="primary" @click="modifySubmit">确定</el-button>
      </div>
    </el-dialog>
    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item label="导出条数" label-width="120px" prop="exportSize">
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport">确认导出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  searchOperationGateRecords,
  findParkingGateByParam,
} from "@/api/entranceMonitoring";
import SuggestParking from "@/components/SuggestParking";
import {addParkingOrderExit, updateVehicleNumPlate,} from "@/api/parkingOrder";
import BaseMixin from "@/mixins/base";
import Permission from "@/mixins/permission";
import {dateFormat} from "@/utils/utils";
import {doExport} from "@/api/common";
import {
  checkToken,
} from "@/api/common";
import {MessageBox} from "element-ui";

export default {
  name: "parking_order_inside",
  mixins: [BaseMixin, Permission],
  data() {
    const today = dateFormat("yyyy-mm-dd", new Date());
    const beginDate = today;
    // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
    const endDate = today;
    return {
      parkingGateList:[],
      dataList: [],
      summaryData:{},
      // isLoading: false,
      completeDialogFormVisible: false, //控制完结弹窗
      modifyDialogFormVisible: false, //修改车牌弹窗
      total: "0",
      discountAmountStats: "0",
      receivableAmountStats: "0",
      transactionAmountStats: "0",
      // 完结表单
      completeForm: {
        parkingOrderIntoId: "",
        endTime: "",
      },
      rules: {
        endTime: [
          {required: true, message: "请选择日期时间", trigger: "change"},
        ],
      },
      //修改车牌表单
      modifyForm: {
        parkingOrderIntoId: "",
        parkingSpaceId: "",
        color: "",
        numPlate: "",
        reason: "",
      },
      modifyRules: {
        color: [
          {required: true, message: "请选择车牌颜色", trigger: "change"},
        ],
        numPlate: [
          {required: true, message: "请输入车牌号码", trigger: "blur"},
        ],
        reason: [
          {required: true, message: "请输入修改原因", trigger: "blur"},
          {max: 100, message: "长度不能超过100个字符", trigger: "blur"},
        ],
      },
      pickerOptions: {
        disabledDate(time){
          return time.getTime()< new Date().getTime()-24 * 3600 * 1000 *90
              || time.getTime()> new Date().getTime();
        },
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dialogFormVisible: false, //控制审核弹窗层显示
      photoDialogFormVisible: false,
      orderType: {
        // 0 临停，1白名单，2黑名单，3长租，4预存，5访客，6共享
        0: "临停",
        1: "白名单",
        2: "黑名单",
        3: "长租",
        4: "预存",
        5: "访客",
        6: "共享",
      },
      orderStatus: {
        0: "正常",
        // 1: "无效",
        // 2: "审核",
        // 3: "复议",
        // "": "正常",
      },
      payStatus: {
        // "": "",
        '0': "欠费",
        '1': "不欠费",
        '2': "其他",
      },
      query: {
        parkingLotId: "", //停车场id
        operationType: "", //抬落杆类型
        parkingLotGateId: "",
        timeValues: [beginDate, endDate], //时间
        startDate: "",
        endDate: "",
      },
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
    };
  },
  components: {
    SuggestParking,
  },
  methods: {
    onChangeParkingLot(item){
      findParkingGateByParam(
          {
            parkingLotId: item.id,
          }
      ).then((res) => {
        if (res && res.code === 30 && res.result) {
          this.parkingGateList = res.returnObject
        }
      });
    },
    onSubmit() {
      console.log("submit!");
    },
    //查看停车订单详情
    goDetail(item) {
      sessionStorage.setItem("order_detail", JSON.stringify(item));
      this.$router.push("/road_outside/parking_order/parking_order_detail");
    },
    //重置查询条件
    resetQueryForm() {
      const today = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = today;
      // const endDate = dateFormat("yyyy-mm-dd HH:MM:SS", new Date());
      const endDate = today;
      this.query = {
        parkingLotId: "", //停车场id
        operationType: "", //抬落杆类型
        parkingLotGateId: "",
        timeValues: [beginDate, endDate], //时间
        startDate: "",
        endDate: "",
      };
      this.query.timeValues = [];
      this.pagination.pageSize = 10;
      this.getNowDate();
      this.doSearch();
    },
    doSearch() {
      this.pagination.pageNo = 1;
      this.search();
    },
    async search() {
      checkToken()
          .then(async res => {
            console.log('checkToken', res)
            if (!res || res.code !== 30) {
              MessageBox.alert('您的登录信息已超时，请重新登录！', '系统提示', {
                confirmButtonText: '重新登录',
                callback: () => {
                  window.localStorage.clear()
                  window.$vue.$router.push('/login')
                }
              });
            } else {
              this.isLoading = true;
              const params = this.paramFormat(this.query);
              if (this.query.timeValues != null) {
                if (this.query.driveType == '') {
                  this.$message.warning('请选择时间范围后重试')
                  return false
                }
                params["startDate"] = this.query.timeValues[0];
                params["endDate"] = this.query.timeValues[1];
              }
              const res = await searchOperationGateRecords(params);
              // const res = await getParkingOrderByPage({
              //   pageNo: this.pagination.pageNo,
              //   pageSize: this.pagination.pageSize,
              //   userId: "1",
              // });
              this.isLoading = false;
              if (res && res.code === 30 && res.result) {
                const returnObject = res.returnObject;
                if (returnObject !== "") {
                  this.dataList = returnObject.records;
                  this.summaryData = returnObject
                  this.pagination.totalSize = returnObject.total;
                  this.pagination.totalPages = returnObject.pages;
                  this.total = returnObject.total;
                  this.form.exportSize = returnObject.total;
                  // if (returnObject.stats.discountAmountStats.sum !== undefined) {
                  //   this.discountAmountStats =
                  //       returnObject.stats.discountAmountStats.sum;
                  // }
                  // if (returnObject.stats.receivableAmountStats.sum !== undefined) {
                  //   this.receivableAmountStats =
                  //       returnObject.stats.receivableAmountStats.sum;
                  // }
                  // if (returnObject.stats.transactionAmountStats.sum !== undefined) {
                  //   this.transactionAmountStats =
                  //       returnObject.stats.transactionAmountStats.sum;
                  // }
                } else {
                  this.dataList = [];
                  this.pagination.totalSize = 0;
                  this.pagination.totalPages = 0;
                  this.total = 0;
                  this.discountAmountStats = 0;
                  this.receivableAmountStats = 0;
                  this.transactionAmountStats = 0;
                }
              }
            }
          })
    },
    //完结弹出窗
    complete(item) {
      this.completeDialogFormVisible = true;
      this.completeForm.parkingOrderIntoId = item;
    },
    //添加完结
    submitForm() {
      this.$refs.completeForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.completeForm.parkingOrderIntoId;
          const endTime = this.completeForm.endTime;
          console.log(endTime);
          addParkingOrderExit(parkingOrderIntoId, endTime).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "完结成功",
                type: "success",
              });
              this.completeDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //关闭完结弹窗清空表单
    completeDialogBeforeClosed(done) {
      this.$refs.completeForm.resetFields();
      done();
    },

    //修改车牌提交
    modifySubmit() {
      this.$refs.modifyForm.validate((valid) => {
        if (valid) {
          const parkingOrderIntoId = this.modifyForm.parkingOrderIntoId;
          const color = this.modifyForm.color;
          const numPlate = this.modifyForm.numPlate;
          const reason = this.modifyForm.reason;
          const parkingSpaceId = this.modifyForm.parkingSpaceId;

          updateVehicleNumPlate(
              parkingOrderIntoId,
              parkingSpaceId,
              numPlate,
              color,
              reason
          ).then((res) => {
            if (res && res.code === 30 && res.result) {
              this.$message({
                message: "修改车牌成功",
                type: "success",
              });
              this.modifyDialogFormVisible = false;
              this.doSearch();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //修改车牌弹窗关闭时清理表单
    modifyDialogBeforeClosed(done) {
      this.$refs.modifyForm.resetFields();
      done();
    },
    //关闭修改车牌
    closeModify() {
      this.modifyDialogFormVisible = false;
      this.$refs.modifyForm.resetFields();
    },
    // handleSizeChange(val) {
    //   console.log(`每页 ${val} 条`);
    // },
    // handleCurrentChange(val) {
    //   console.log(`当前页: ${val}`);
    // },
    //修改车牌弹窗
    modifyLicensePlate(parkingOrderIntoId, parkingSpaceId) {
      this.modifyDialogFormVisible = true;
      this.modifyForm.parkingOrderIntoId = parkingOrderIntoId;
      this.modifyForm.parkingSpaceId = parkingSpaceId;
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: "正在导出",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0)",
      });
      const params = this.paramFormat(this.query);
      if (this.query.timeValues.length > 0) {
        params["startDate"] = this.query.timeValues[0];
        params["endDate"] = this.query.timeValues[1];
      }
      params["isExport"] = "1";
      params["exportSize"] = this.form.exportSize;
      // const res = searchIncomeFlow(params);
      // var text = window.location.hostname;
      // console.log(text);
      // window.open("http://172.16.69.129:81" + "/ParkingManagermentController/getEsParkingOrderRecord?" + objectToQueryString(params));
      doExport(
          "/ParkingManagermentController/getEsParkingOrderRecord",
          params,
          "路内停车订单.xlsx"
      );
      loading.close();
      this.exportDialogFormVisible = false;

      // if (res && res.code === 30 && res.result) {
      //   loading.close();
      //   this.exportDialogFormVisible = false;
      // }
    },
    //获取当前时间
    getNowDate() {
      const beginToday = dateFormat("yyyy-mm-dd", new Date());
      const beginDate = beginToday;
      const endToday = dateFormat("yyyy-mm-dd", new Date());
      const endDate = endToday;
      this.query.timeValues = [beginDate, endDate];
    },
  },
};
</script>

<style lang="less" scoped>
.photoShow {
  display: flex;
  flex-direction: row;

  .photo-width {
    /deep/ .el-carousel__container {
      width: 430px;
    }
  }

  .driveInPhoto {
    margin: 0 34px;
  }

  .driveOutPhoto {
    margin: 0 26px;
  }
}

/deep/ .el-button--text{
  color: #0768FD !important;
}
</style>
